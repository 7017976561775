<template>
  <div>
    <!--    <div class="breadCurmb mt-3">-->

    <!--      <label>-->
    <!--        <router-link to="/admin/home">{{ $t("Home") }}</router-link>-->
    <!--      </label>-->

    <!--      <span>/</span>-->

    <!--      <label>{{ $t("Sales") }}</label>-->

    <!--      <span>/</span>-->

    <!--      <label style="cursor:pointer" @click="goBack">-->

    <!--        <span v-if="subscribeFrom">  {{ $t(subscribeFrom) }}  </span>-->

    <!--        <span v-else>-->
    <!--          {{ $t('Subscribes') }}-->
    <!--        </span>-->

    <!--      </label>-->

    <!--      <span>/</span>-->

    <!--      <label>{{$t("Edit") }}</label>-->

    <!--    </div>-->

    <!-- Spinner Overlay -->
    <div v-if="saveClick">
      <div class="spinner-overlay">
        <div class="spinner"></div>
      </div>
      <h3 class="fixed-text">
        {{ $t("Please wait until finish saving ...") }}
      </h3>
    </div>

    <v-row class="mt-3">
      <v-col md="12">
        <v-alert border="top" color="green lighten-2" dark>
          <h4>{{ $t("EditSubscribe") }}</h4>
        </v-alert>
      </v-col>
    </v-row>

    <v-row class="mt-3" v-if="zohoError">
      <v-col md="12">
        <v-alert border="top" color="red lighten-2" dark>
          <h4>{{ zohoError }}</h4>
        </v-alert>
      </v-col>
    </v-row>

    <v-card-text>
      <v-container v-if="errorsApi.length > 0">
        <h1>الاخطاء</h1>
        <div v-for="(error, index) in errorsApi" :key="index">
          {{ error }}
        </div>
      </v-container>
    </v-card-text>

    <v-card-text>
      <v-container fluid>
        <v-row v-if="selectedCustomer.name">
          <v-col class="py-0" :cols="3">
            <div>
              <label>{{ $t("SubscriberName") }}</label>
              <v-text-field
                class="d-block my-2"
                type="text"
                hide-details
                dense
                outlined
                disabled
                v-model="selectedCustomer.name"
              ></v-text-field>
            </div>
          </v-col>
          <v-col class="py-0" :cols="3">
            <div>
              <label>{{ $t("Mobile") }}</label>
              <v-text-field
                class="d-block my-2"
                type="text"
                hide-details
                dense
                outlined
                disabled
                v-model="selectedCustomer.phone_number"
              ></v-text-field>
            </div>
          </v-col>
          <v-col class="py-0" :cols="3">
            <div>
              <label>{{ $t("Email") }}</label>
              <v-text-field
                class="d-block my-2"
                type="text"
                hide-details
                dense
                outlined
                disabled
                v-model="selectedCustomer.email"
              ></v-text-field>
            </div>
          </v-col>
          <v-col class="py-0" :cols="1">
            <div>
              <label>{{ $t("Carb") }}</label>
              <v-text-field
                class="d-block my-2"
                type="text"
                hide-details
                dense
                outlined
                disabled
                v-model="selectedCustomer.carb"
              ></v-text-field>
            </div>
          </v-col>
          <v-col class="py-0" :cols="1">
            <div>
              <label>{{ $t("Protein") }}</label>
              <v-text-field
                class="d-block my-2"
                type="text"
                hide-details
                dense
                outlined
                disabled
                v-model="selectedCustomer.protein"
              ></v-text-field>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="py-0 d-flex align-center" cols="12" md="6">
            <v-col class="py-0 d-flex align-center" cols="6" md="4">
              <v-checkbox v-model="changeCustomerSubscription"></v-checkbox>
              <label class="mr-3">{{ $t("IsEdit") }} العميل</label>
            </v-col>
            <v-col
              class="py-0"
              cols="6"
              md="8"
              v-if="changeCustomerSubscription"
            >
              <search-customer
                :store="$store"
                @customer-selected="handleCustomerSelected"
              ></search-customer>
            </v-col>
          </v-col>

          <v-col cols="12" md="6">
            <div
              :class="{
                'has-error': errors.has(`addEditValidation.NotMainIngredients`),
              }"
            >
              <label>{{ $t("NotMainIngredients") }}</label>
              <v-autocomplete
                class="d-block my-2"
                name="NotMainIngredients"
                data-vv-scope="addEditValidation"
                v-validate="''"
                :data-vv-as="$t('NotMainIngredients')"
                hide-details
                dense
                outlined
                v-model="formData.customer.not_ingredients"
                item-value="id"
                item-text="name"
                :items="notMainIngredientList"
                multiple
                clearable
              ></v-autocomplete>
            </div>

            <div
              class="help-block"
              v-if="errors.has(`addEditValidation.NotMainIngredients`)"
            >
              {{ errors.first(`addEditValidation.NotMainIngredients`) }}
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-text>
      <v-container fluid>
        <v-row>
          <v-col class="py-0" cols="12" md="12">
            <div
              :class="{
                'has-error': errors.has(`addEditValidation.notes`),
              }"
            >
              <label>{{ $t("Notes") }}</label>
              <v-textarea
                class="d-block my-2"
                type="text"
                name="notes"
                rows="4"
                data-vv-scope="addEditValidation"
                v-validate="''"
                :data-vv-as="$t('Notes')"
                hide-details
                dense
                outlined
                v-model="formData.subscribe.notes"
              ></v-textarea>
            </div>

            <div
              class="help-block"
              v-if="errors.has(`addEditValidation.notes`)"
            >
              {{ errors.first(`addEditValidation.notes`) }}
            </div>
          </v-col>

          <v-col class="py-0" cols="3">
            <div
              :class="{
                'has-error': errors.has(`addEditValidation.Package`),
              }"
            >
              <label>{{ $t("Package") }}</label>
              <v-autocomplete
                class="d-block my-2"
                name="Package"
                data-vv-scope="addEditValidation"
                v-validate="'required'"
                :data-vv-as="$t('Package')"
                hide-details
                dense
                outlined
                v-model="formData.subscribe.package_id"
                item-value="id"
                item-text="name"
                :items="packageList"
                clearable
                @change="changePackage(formData.subscribe.package_id)"
              ></v-autocomplete>
            </div>

            <div
              class="help-block"
              v-if="errors.has(`addEditValidation.Package`)"
            >
              {{ errors.first(`addEditValidation.Package`) }}
            </div>
          </v-col>

          <template v-if="packageDetails">
            <v-col class="py-0" cols="3">
              <div
                :class="{
                  'has-error': errors.has(`addEditValidation.price`),
                }"
              >
                <label>{{ $t("Price") + " المطلوب" }}</label>
                <v-text-field
                  class="d-block my-2"
                  type="text"
                  name="price"
                  data-vv-scope="addEditValidation"
                  v-validate="''"
                  disabled
                  :data-vv-as="$t('Price')"
                  hide-details
                  dense
                  outlined
                  v-model="packageDetails.price"
                ></v-text-field>
              </div>

              <div
                class="help-block"
                v-if="errors.has(`addEditValidation.price`)"
              >
                {{ errors.first(`addEditValidation.price`) }}
              </div>
            </v-col>

            <v-col class="py-0" :cols="2">
              <div>
                <v-switch
                  class="d-block mt-8"
                  v-model="formData.subscribe.paid"
                  :value="1"
                  :false-value="0"
                  hide-details
                  dense
                  outlined
                  inset
                  :color="formData.subscribe.paid == 1 ? 'green' : 'red'"
                >
                  <template v-slot:label>
                    {{
                      formData.subscribe.paid == 1 ? $t("Paid") : $t("Not Paid")
                    }}
                  </template>
                </v-switch>
              </div>
            </v-col>

            <v-col class="py-0" cols="3">
              <div
                :class="{
                  'has-error': errors.has(`addEditValidation.amount`),
                }"
              >
                <label>{{ $t("Price") + " المدفوع" }}</label>
                <v-text-field
                  class="d-block my-2"
                  type="number"
                  name="amount"
                  data-vv-scope="addEditValidation"
                  v-validate="'required'"
                  :data-vv-as="$t('Price')"
                  hide-details
                  dense
                  outlined
                  v-model="formData.transaction.amount"
                ></v-text-field>
              </div>

              <div
                class="help-block"
                v-if="errors.has(`addEditValidation.amount`)"
              >
                {{ errors.first(`addEditValidation.amount`) }}
              </div>
            </v-col>

            <v-col class="py-0" :cols="3" v-if="formData.subscribe.paid == 1">
              <div
                :class="{
                  'has-error': errors.has(`addEditValidation.DepositeAccount`),
                }"
              >
                <label>{{ $t("DepositeAccount") }}</label>
                <v-autocomplete
                  class="d-block my-2"
                  name="DepositeAccount"
                  data-vv-scope="addEditValidation"
                  v-validate="'required'"
                  :data-vv-as="$t('DepositeAccount')"
                  hide-details
                  dense
                  outlined
                  v-model="formData.transaction.deposit"
                  item-value="account_id"
                  item-text="account_name"
                  :items="depositAccountsList"
                  clearable
                ></v-autocomplete>
              </div>

              <div
                class="help-block"
                v-if="errors.has(`addEditValidation.DepositeAccount`)"
              >
                {{ errors.first(`addEditValidation.DepositeAccount`) }}
              </div>
            </v-col>

            <v-col class="py-0" :cols="3" v-if="formData.subscribe.paid == 1">
              <div
                :class="{
                  'has-error': errors.has(`addEditValidation.Payment_mode`),
                }"
              >
                <label>{{ $t("Payment_mode") }}</label>
                <v-autocomplete
                  class="d-block my-2"
                  name="Payment_mode"
                  data-vv-scope="addEditValidation"
                  v-validate="'required'"
                  :data-vv-as="$t('Payment_mode')"
                  hide-details
                  dense
                  outlined
                  v-model="formData.transaction.payment_mode"
                  item-value="name"
                  item-text="name"
                  :items="paymentModesList"
                  clearable
                ></v-autocomplete>
              </div>

              <div
                class="help-block"
                v-if="errors.has(`addEditValidation.Payment_mode`)"
              >
                {{ errors.first(`addEditValidation.Payment_mode`) }}
              </div>
            </v-col>

            <v-col class="py-0" :cols="3">
              <div
                :class="{
                  'has-error': errors.has(`addEditValidation.quantity`),
                }"
              >
                <label>{{ $t("Quantity") }}</label>
                <v-text-field
                  class="d-block my-2"
                  type="text"
                  name="quantity"
                  data-vv-scope="addEditValidation"
                  v-validate="'required|numeric|min_value:1'"
                  :data-vv-as="$t('Quantity')"
                  hide-details
                  dense
                  outlined
                  v-model="formData.subscribe.quantity"
                ></v-text-field>
              </div>

              <div
                class="help-block"
                v-if="errors.has(`addEditValidation.quantity`)"
              >
                {{ errors.first(`addEditValidation.quantity`) }}
              </div>
            </v-col>

            <!--            <v-col class="py-0" :cols="3">-->
            <!--              <div-->
            <!--                  :class="{-->
            <!--                      'has-error': errors.has(-->
            <!--                        `addEditValidation.paid`-->
            <!--                      ),-->
            <!--                    }"-->
            <!--              >-->
            <!--                <label>{{ $t("SubscribeStatus") }}</label>-->
            <!--                <v-autocomplete-->
            <!--                    class="d-block my-2"-->
            <!--                    name="paid"-->
            <!--                    data-vv-scope="addEditValidation"-->
            <!--                    v-validate="'required'"-->
            <!--                    :data-vv-as="$t('SubscribeStatus')"-->
            <!--                    hide-details-->
            <!--                    dense-->
            <!--                    outlined disabled-->
            <!--                    v-model="formData.subscribe.paid"-->
            <!--                    item-value="id"-->
            <!--                    item-text="name"-->
            <!--                    :items="subscribeStatusList"-->
            <!--                    clearable-->
            <!--                ></v-autocomplete>-->
            <!--              </div>-->

            <!--              <div-->
            <!--                  class="help-block"-->
            <!--                  v-if="errors.has(`addEditValidation.paid`)"-->
            <!--              >-->
            <!--                {{ errors.first(`addEditValidation.paid`) }}-->
            <!--              </div>-->
            <!--            </v-col>-->

            <v-col class="py-0" cols="12" md="6" v-if="!$route.params.id">
              <div
                :class="{
                  'has-error': errors.has(`addEditValidation.number_of_days`),
                }"
              >
                <label>{{ $t("NumberOfDaysPackage") }}</label>
                <v-text-field
                  class="d-block my-2"
                  type="text"
                  name="number_of_days"
                  data-vv-scope="addEditValidation"
                  v-validate="'numeric'"
                  :disabled="true"
                  :data-vv-as="$t('NumberOfDaysPackage')"
                  hide-details
                  dense
                  outlined
                  v-model="formData.subscribe.number_of_days"
                ></v-text-field>
              </div>

              <div
                class="help-block"
                v-if="errors.has(`addEditValidation.number_of_days`)"
              >
                {{ errors.first(`addEditValidation.number_of_days`) }}
              </div>
            </v-col>

            <v-col class="py-0" cols="12" md="6">
              <div
                :class="{
                  'has-error': errors.has(`addEditValidation.start_date`),
                }"
              >
                <label>{{ $t("StartDate") }}</label>
                <el-date-picker
                  v-validate="'required'"
                  style="width: 100%"
                  v-model="formData.subscribe.start_date"
                  type="date"
                  class="mt-2"
                >
                </el-date-picker>
              </div>

              <div
                class="help-block"
                v-if="errors.has(`addEditValidation.start_date`)"
              >
                {{ errors.first(`addEditValidation.start_date`) }}
              </div>
            </v-col>

            <v-col
              class="py-0"
              cols="12"
              md="6"
              v-if="packageDetails && $route.params.id"
            >
              <label>{{ $t("SubscriptionsDays") }}</label>
              <v-alert color="blue">
                {{ formData.subscribe.number_of_days }}
              </v-alert>
            </v-col>

            <v-col
              style="margin-top: 2%; margin-bottom: 2%"
              class="py-0"
              cols="12"
              md="6"
              v-if="packageDetails && $route.params.id"
            >
              <v-row>
                <v-col
                  class="py-0 d-flex align-center"
                  cols="6"
                  md="4"
                  cols-xs="6"
                >
                  <!-- Adjusted for small screens -->
                  <label class="mr-2">{{ $t("IsEdit") }}</label>
                  <v-checkbox
                    @change="
                      changeRemindDays(formData.subscribe.is_remind_days)
                    "
                    v-model="formData.subscribe.is_remind_days"
                  ></v-checkbox>
                </v-col>
                <v-col
                  class="py-0 d-flex align-center"
                  cols="6"
                  md="8"
                  cols-xs="6"
                >
                  <!-- Adjusted for small screens -->
                  <label class="mr-2">{{ $t("RemindDays") }}</label>
                  <v-text-field
                    v-if="formData.subscribe.is_remind_days"
                    class="d-block my-2"
                    type="text"
                    hide-details
                    dense
                    outlined
                    v-model="formData.subscribe.count_of_remind_day"
                    color="orange"
                  ></v-text-field>
                  <v-alert v-else color="orange" class="mr-2">{{
                    formData.subscribe.remind_days
                  }}</v-alert>
                </v-col>
              </v-row>
            </v-col>

            <!--            <v-col class="py-0" :cols="3">-->
            <!--              <div-->
            <!--                  :class="{-->
            <!--                      'has-error': errors.has(`addEditValidation.total`),-->
            <!--                    }"-->
            <!--              >-->
            <!--                <label>{{ $t("Total") }}</label>-->
            <!--                <v-text-field-->
            <!--                    class="d-block my-2"-->
            <!--                    type="text"-->
            <!--                    name="total"-->
            <!--                    data-vv-scope="addEditValidation"-->
            <!--                    v-validate="''"-->
            <!--                    disabled-->
            <!--                    :data-vv-as="$t('Total')"-->
            <!--                    hide-details-->
            <!--                    dense-->
            <!--                    outlined-->
            <!--                    v-model="formData.subscribe.total"-->
            <!--                ></v-text-field>-->
            <!--              </div>-->

            <!--              <div-->
            <!--                  class="help-block"-->
            <!--                  v-if="errors.has(`addEditValidation.total`)"-->
            <!--              >-->
            <!--                {{ errors.first(`addEditValidation.total`) }}-->
            <!--              </div>-->
            <!--            </v-col>-->

            <v-col class="py-0" cols="12" md="6">
              <label>{{ $t("اليوم الوطني") }}</label>
              <v-checkbox
                @change="changeNationalDay(formData.subscribe.national_day)"
                v-model="formData.subscribe.national_day"
              >
              </v-checkbox>
            </v-col>

            <v-col class="py-0" cols="12" md="6">
              <div
                :class="{
                  'has-error': errors.has(`addEditValidation.Period`),
                }"
              >
                <label>{{ $t("Period") }}</label>
                <v-autocomplete
                  class="d-block my-2"
                  name="Period"
                  data-vv-scope="addEditValidation"
                  v-validate="''"
                  :data-vv-as="$t('Period')"
                  hide-details
                  dense
                  outlined
                  v-model="formData.subscribe.period"
                  item-value="id"
                  item-text="name"
                  :items="periodList"
                  clearable
                ></v-autocomplete>
              </div>

              <div
                class="help-block"
                v-if="errors.has(`addEditValidation.Period`)"
              >
                {{ errors.first(`addEditValidation.Period`) }}
              </div>
            </v-col>

            <v-col class="py-0" cols="12" md="6">
              <div
                :class="{
                  'has-error': errors.has(`addEditValidation.days`),
                }"
              >
                <label>{{ $t("DaysSelected") }}</label>
                <v-autocomplete
                  class="d-block my-2"
                  name="days"
                  data-vv-scope="addEditValidation"
                  v-validate="'required'"
                  :data-vv-as="$t('DaysSelected')"
                  hide-details
                  dense
                  outlined
                  v-model="formData.subscribe.days"
                  item-value="id"
                  item-text="name"
                  :items="dayList"
                  @change="dayListChanged"
                  multiple
                  clearable
                ></v-autocomplete>
              </div>

              <div
                class="help-block"
                v-if="errors.has(`addEditValidation.days`)"
              >
                {{ errors.first(`addEditValidation.days`) }}
              </div>
            </v-col>

            <v-col class="py-0" cols="12" md="6">
              <div
                :class="{
                  'has-error': errors.has(`addEditValidation.address_id`),
                }"
              >
                <label>{{ $t("عنوان التوصيل") }}</label>
                <v-autocomplete
                  class="d-block my-2"
                  name="address_id"
                  data-vv-scope="addEditValidation"
                  v-validate="'required'"
                  :data-vv-as="$t('address_id')"
                  hide-details
                  dense
                  outlined
                  v-model="formData.customer.address_id"
                  item-value="id"
                  item-text="type"
                  :items="addressesList"
                  clearable
                ></v-autocomplete>
              </div>

              <div
                class="help-block"
                v-if="errors.has(`addEditValidation.address_id`)"
              >
                {{ errors.first(`addEditValidation.address_id`) }}
              </div>
            </v-col>
            <v-col class="py-0" cols="12" md="6" v-if="formData.transaction">
              <div>
                <label>{{ $t("بيانات الدفع ") }}</label>
                <el-date-picker
                  disabled=""
                  style="width: 100%"
                  v-model="formData.transaction.created_at"
                  type="date"
                  class="mt-2"
                >
                </el-date-picker>
              </div>
            </v-col>

            <v-container fluid>
              <div>
                <el-button @click="addNew" type="primary float-right mb-5">{{
                  $t("Add")
                }}</el-button>
              </div>

              <table id="pacakgeFeaturesTable">
                <tr>
                  <th>#</th>
                  <th>اسم المجموعة</th>
                  <th>الكمية</th>
                  <th>السعر</th>
                  <th>السعر المدفوع</th>
                  <th>العمليات</th>
                </tr>

                <tr
                  v-for="(l, lIndex) in formData.subscribe.group_subscribe"
                  :key="lIndex"
                >
                  <td>
                    {{ lIndex + 1 }}
                  </td>
                  <td>
                    <div
                      :class="{
                        'has-error': errors.has(
                          `addEditValidation.group${lIndex}`
                        ),
                      }"
                    >
                      <label>{{ $t("Group") }}</label>
                      <v-autocomplete
                        class="d-block my-2"
                        :name="`group${lIndex}`"
                        data-vv-scope="addEditValidation"
                        v-validate="'required'"
                        :data-vv-as="$t('Group')"
                        hide-details
                        dense
                        outlined
                        v-model="l.id"
                        item-value="id"
                        item-text="name"
                        :items="listGroup"
                        clearable
                      ></v-autocomplete>
                    </div>

                    <div
                      class="help-block"
                      v-if="errors.has(`addEditValidation.group${lIndex}`)"
                    >
                      {{ errors.first(`addEditValidation.group${lIndex}`) }}
                    </div>
                  </td>
                  <td>
                    <div
                      :class="{
                        'has-error': errors.has(
                          `addEditValidation.quantity${lIndex}`
                        ),
                      }"
                    >
                      <label>{{ $t("Quantity") }}</label>
                      <v-text-field
                        class="d-block my-2"
                        :name="`quantity${lIndex}`"
                        data-vv-scope="addEditValidation"
                        v-validate="'required|numeric'"
                        :data-vv-as="$t('Quantity')"
                        hide-details
                        dense
                        outlined
                        v-model="l.quantity"
                      ></v-text-field>
                    </div>

                    <div
                      class="help-block"
                      v-if="errors.has(`addEditValidation.quantity${lIndex}`)"
                    >
                      {{ errors.first(`addEditValidation.quantity${lIndex}`) }}
                    </div>
                  </td>

                  <td>
                    <div
                      :class="{
                        'has-error': errors.has(
                          `addEditValidation.price${lIndex}`
                        ),
                      }"
                    >
                      <label>{{ $t("Price") }}</label>
                      <v-text-field
                        class="d-block my-2"
                        :name="`price${lIndex}`"
                        data-vv-scope="addEditValidation"
                        v-validate="'required|decimal'"
                        :data-vv-as="$t('Price')"
                        hide-details
                        dense
                        outlined
                        v-model="l.price"
                      ></v-text-field>
                    </div>

                    <div
                      class="help-block"
                      v-if="errors.has(`addEditValidation.price${lIndex}`)"
                    >
                      {{ errors.first(`addEditValidation.price${lIndex}`) }}
                    </div>
                  </td>

                  <td>
                    <div
                      :class="{
                        'has-error': errors.has(
                          `addEditValidation.transaction_price${lIndex}`
                        ),
                      }"
                    >
                      <label>{{ $t("TransactionPrice") }}</label>
                      <v-text-field
                        class="d-block my-2"
                        :name="`transaction_price${lIndex}`"
                        data-vv-scope="addEditValidation"
                        v-validate="'required|decimal'"
                        :data-vv-as="$t('TransactionPrice')"
                        hide-details
                        dense
                        outlined
                        v-model="l.transaction_price"
                      ></v-text-field>
                    </div>

                    <div
                      class="help-block"
                      v-if="
                        errors.has(
                          `addEditValidation.transaction_price${lIndex}`
                        )
                      "
                    >
                      {{
                        errors.first(
                          `addEditValidation.transaction_price${lIndex}`
                        )
                      }}
                    </div>
                  </td>

                  <td>
                    <v-icon
                      class="my-0 mr-2 ml-2"
                      dense
                      @click="removeRow(lIndex)"
                      color="danger"
                      >mdi-delete</v-icon
                    >
                  </td>
                </tr>
              </table>
            </v-container>
          </template>
        </v-row>
      </v-container>
    </v-card-text>

    <v-container fluid>
      <v-row class="float-left">
        <v-btn
          class="mr-2 ml-5"
          color="success"
          dark
          outlined
          small
          @click="save"
        >
          {{ $t("Save") }}
        </v-btn>
        <v-btn
          class="mx-5"
          color="danger"
          dark
          outlined
          small
          @click="saveWithBill"
        >
          {{ $t("SaveWithBill") }}
        </v-btn>
      </v-row>
    </v-container>

    <!-- Confirmation Dialog -->
    <v-dialog v-model="confirmDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">{{ $t("Confirm") }}</v-card-title>
        <v-card-text>هل انت متاكد سوف يتم تحديث العميل الحالي</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="confirmSelection(true)">{{
            $t("Yes")
          }}</v-btn>
          <v-btn color="red darken-1" text @click="confirmSelection(false)">{{
            $t("No")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import moment from "moment";
import SearchCustomer from "@/views/pages/sale/subscribe/new/search_customer.vue";

export default {
  name: "createSubscription",
  components: { SearchCustomer },
  data() {
    return {
      zohoError: null,
      depositAccountsList: [],
      paymentModesList: [],
      saveClick: false,
      confirmDialog: false,
      tempCustomer: null,
      errorsApi: [],
      user: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : [],
      subscribeFrom: localStorage.getItem("addSubscribeFrom") || "all",
      defaultDays: [],
      loadClicked: false,
      msg: "برجاء الانتظار لحين اكتمال العملية",
      activeTab: 0,
      changeCustomerSubscription: false,
      selectedCustomer: {
        name: null,
        phone_number: null,
        email: null,
      },
      formData: {
        customer: {
          id: null,
          not_ingredients: [],
          address_id: null,
        },
        subscribe: {
          quantity: null,
          package_id: null,
          day_status: 1,
          discount: null,
          days: [],
          start_date: null,
          total: null,
          number_of_days: null,
          remind_days: null,
          paid: 0,
          group_subscribe: [], // id, quentity
          is_remind_days: false,
          count_of_remind_day: 0,
          count_subscriptions_day: null,
          national_day: false,
          period: null,
          notes: null,
          save_with_zoho: false,
        },
        transaction: {
          amount: 0,
          deposit: null,
          payment_mode: null,
          created_at: null,
        },
      },
      packageList: [],
      cityList: [],
      addressesList: [],
      neighborhoodList: [],
      PeopleGroup: [],
      notMainIngredientList: [],
      packageDetails: null,
      listGroup: [],
      periodList: [
        {
          id: "am",
          name: "صباحي",
        },
        {
          id: "pm",
          name: "مسائي",
        },
      ],
      subscribeStatusList: [
        {
          id: 1,
          name: this.$t("Paid"),
        },
        {
          id: 0,
          name: this.$t("NotPaid"),
        },
      ],
      dayList: [
        { id: 6, name: this.$t("Saturday") },
        { id: 0, name: this.$t("Sunday") },
        { id: 1, name: this.$t("Monday") },
        { id: 2, name: this.$t("Tuesday") },
        { id: 3, name: this.$t("Wedensday") },
        { id: 4, name: this.$t("Thursday") },
      ],
    };
  },
  created() {
    this.$store
      .dispatch(`zoho/checkAuthorized`)
      .then((res) => {
        this.zohoError = null;

        this.$store
          .dispatch(`zoho/getDepositToAccounts`)
          .then((res) => {
            this.depositAccountsList = res;
          })
          .catch((err) => {
            console.log(err);
          });

        this.$store
          .dispatch(`zoho/getPaymentModes`)
          .then((res) => {
            this.paymentModesList = res.payment_modes;
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
        this.zohoError = this.$t("Not Connect on Zoho");
      });

    let check_permessions = false;

    if (
      this.user &&
      this.user.permissions &&
      !this.user.permissions.includes("store_subscribe")
    ) {
      this.$router.push({ name: "subscribe" });

      this.notifyCustomError("Error", "لا يوجد لديك صلاحية");
    }

    this.$store.dispatch(`subscribe/mainIngredientList`).then((res) => {
      this.mainIngredientList = res.data;
    });
    this.$store.dispatch(`subscribe/notMainIngredientList`).then((res) => {
      this.notMainIngredientList = res.data;
    });

    this.$store.dispatch(`subscribe/packageList`).then((res) => {
      this.packageList = res.data;
    });

    this.$store.dispatch(`package/listGroup`).then((res) => {
      this.listGroup = res.data;
    });

    const loading = this.$loading({
      lock: true,
      text: "Loading",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.7)",
    });
    this.$store
      .dispatch(`newSubscribe/findData`, this.$route.params.id)
      .then((res) => {
        this.changePackageInitalize(res.data.subscribe.package_id);
        // res.data.subscribe.day_status = 1;

        let newData = JSON.parse(JSON.stringify(res.data));
        this.defaultDays = newData.subscribe.days;
        // res.data.subscribe.count_of_remind_day= res.data.subscribe.remind_days;
        this.formData = res.data;
        if (res.data.transaction === null) {
          this.formData.transaction = {
            amount: this.formData.subscribe.total,
            created_at: null,
          };
        }

        if (
          this.formData.transaction &&
          this.formData.transaction.amount == 0
        ) {
          this.formData.transaction.amount = this.formData.subscribe.total;
        }

        this.formData.customer.not_ingredients = res.data.not_ingredients;
        this.selectedCustomer = res.data.customer;
        this.addressesList = res.data.customer.addresses;
        const foundAddress = this.addressesList.find(
          (address) => address.id === res.data.delivery.address_id
        );

        // Check if we found the address
        if (foundAddress) {
          // If address found, assign its id to formData.customer.address_id
          this.formData.customer.address_id = foundAddress.id;
        }

        if (this.$route.params.type_sub && this.formData.subscribe) {
          // Set the start_date to tomorrow's date
          this.formData.subscribe.start_date = moment()
            .add(1, "days")
            .format("YYYY-MM-DD");
        }

        loading.close();
      });
  },
  methods: {
    changeRemindDays(val) {
      if (val == false) {
        this.formData.subscribe.count_of_remind_day =
          this.formData.subscribe.remind_days;
      }
    },
    changeNationalDay(val) {
      this.formData.subscribe.national_day == val;
    },
    goBack() {
      this.$router.go(-1);
    },
    // changeRenewSubscribe(val) {
    //   if (val == 2) {
    //     this.formData.subscribe.days = this.defaultDays;
    //     this.formData.subscribe.renew_date = null;
    //     this.formData.subscribe.renew_day_numbers = null;
    //   }
    //   else {
    //     this.formData.subscribe.renew_date = new Date();
    //     this.formData.subscribe.renew_day_numbers = this.formData.subscribe.remind_days;
    //   }
    // },
    dayListChanged() {
      // this.formData.subscribe.renewSubscribe = 1;
      // this.formData.subscribe.renew_date = new Date();
      // this.formData.subscribe.renew_day_numbers = this.formData.subscribe.remind_days;
    },
    removeRow(index) {
      this.formData.subscribe.group_subscribe.splice(index, 1);
    },
    addNew() {
      let listObj = {
        id: null,
        quantity: null,
        price: null,
        transaction_price: null,
      };
      this.formData.subscribe.group_subscribe.push(listObj);
    },
    next() {
      this.activeTab += 1;
    },
    changeDiscount() {
      if (
        this.packageDetails &&
        this.packageDetails.price &&
        this.formData &&
        this.formData.subscribe &&
        this.formData.subscribe.discount
      ) {
        let total =
          parseInt(this.packageDetails.price) -
          parseInt(this.formData.subscribe.discount);
        this.formData.subscribe.total = total;
      } else {
        this.formData.subscribe.total = this.packageDetails.price;
      }
    },
    getNeighborhoodList(id) {
      this.$store.dispatch(`subscribe/branchList`, id).then((res) => {
        this.neighborhoodList = res.data;
      });
    },
    changePackageInitalize(id) {
      this.$store.dispatch(`subscribe/packageDetails`, id).then((res) => {
        this.packageDetails = res.data;
      });
    },
    changePackage(id) {
      this.$store.dispatch(`subscribe/packageDetails`, id).then((res) => {
        this.packageDetails = res.data;
        this.formData.subscribe.days = res.data.days;
        this.formData.subscribe.group_subscribe = res.data.details;
        this.formData.subscribe.number_of_days = res.data.number_of_days;
      });
    },
    handleCustomerSelected(customer) {
      if (!customer.phone_number) {
        this.notifyError("نآسف هذا العميل ليس لديه هاتف جوال");
        return;
      }

      this.tempCustomer = customer;
      this.confirmDialog = true;
    },
    confirmSelection(confirm) {
      if (confirm) {
        const customer = this.tempCustomer;
        this.formData.customer.id = customer.id;
        this.selectedCustomer.name = customer.name;
        this.selectedCustomer.phone_number = customer.phone_number;
        this.selectedCustomer.email = customer.email;
        this.addressesList = customer.addresses;
      }
      this.changeCustomerSubscription = false;
      this.confirmDialog = false;
      this.tempCustomer = null;
    },

    save() {
      this.loadClicked = true;
      this.saveClick = true;

      // Validate the form
      this.$validator.validateAll("addEditValidation").then((valid) => {
        if (!valid) {
          this.loadClicked = false;
          this.saveClick = false;
          return this.notifyCustomError("Error", "ValidationErrors");
        }

        let sendData = JSON.parse(JSON.stringify(this.formData));

        let contactZoho = {
          contact_name: this.selectedCustomer.name,
          contact_persons: [
            {
              phone: this.selectedCustomer.phone_number,
            },
          ],
        };

        if (this.zohoError == null) {
          this.$store
            .dispatch("zoho/createContact", contactZoho)
            .then((res) => {
              let contact_id = res.contact.contact_id;
              this.loadClicked = false;
              // Add Zoho contact ID to the customer data
              sendData.customer.contact_id = contact_id;

              if (sendData && sendData.subscribe && sendData.subscribe.paid === null) {
                sendData.subscribe.paid = 0;
              }

              // Format the start date
              if (sendData && sendData.subscribe && sendData.subscribe.start_date) {
                sendData.subscribe.start_date = moment(sendData.subscribe.start_date).format("YYYY-MM-DD");
              }

              // Prevent saving with Zoho again
              sendData.subscribe.save_with_zoho = false;

              // Get the current ID and prepare the endpoint
              sendData.id = this.$route.params.id;
              let routeEndPoint = this.$route.params.type_sub
                ? "newSubscribe/renewalData"
                : "newSubscribe/updateData";

              // Send the subscribe data
              this.$store
                .dispatch(routeEndPoint, sendData)
                .then((res) => {
                  this.notifySuccess("Save", res);
                  this.$router.push({ name: "subscribe" });
                })
                .catch((error) => {
                  let errorsArray = [];

                  // Handle API error response
                  if (error.response && error.response.data) {
                    const responseData = error.response.data;

                    if (Array.isArray(responseData.errors)) {
                      // If errors are an array
                      errorsArray = responseData.errors;
                    } else if (typeof responseData.errors === "object") {
                      // If errors are an object
                      errorsArray = Object.values(responseData.errors);
                    } else if (responseData.message) {
                      // If there's a single error message
                      errorsArray.push(responseData.message);
                    }
                  } else {
                    // Log unexpected errors
                    console.error("Unexpected error:", error);
                    errorsArray.push("An unexpected error occurred.");
                  }

                  // Set errors to be displayed in the UI
                  this.errorsApi = errorsArray;
                })
                .finally(() => {
                  this.loadClicked = false;
                  this.saveClick= false;
                });
            })
            .catch((error) => {
              this.loadClicked = false;
              this.saveClick= false;
              console.error("Zoho contact creation failed:", error);
            });
        }
        else {
          let contact_id = null;
              this.loadClicked = false;
              // Add Zoho contact ID to the customer data
              sendData.customer.contact_id = contact_id;

              if (sendData && sendData.subscribe && sendData.subscribe.paid === null) {
                sendData.subscribe.paid = 0;
              }

              // Format the start date
              if (sendData && sendData.subscribe && sendData.subscribe.start_date) {
                sendData.subscribe.start_date = moment(sendData.subscribe.start_date).format("YYYY-MM-DD");
              }

              // Get the current ID and prepare the endpoint
              sendData.id = this.$route.params.id;
              let routeEndPoint = this.$route.params.type_sub
                ? "newSubscribe/renewalData"
                : "newSubscribe/updateData";

              // Send the subscribe data
              this.$store
                .dispatch(routeEndPoint, sendData)
                .then((res) => {
                  this.notifySuccess("Save", res);
                  this.$router.push({ name: "subscribe" });
                })
                .catch((error) => {
                  let errorsArray = [];

                  // Handle API error response
                  if (error.response && error.response.data) {
                    const responseData = error.response.data;

                    if (Array.isArray(responseData.errors)) {
                      // If errors are an array
                      errorsArray = responseData.errors;
                    } else if (typeof responseData.errors === "object") {
                      // If errors are an object
                      errorsArray = Object.values(responseData.errors);
                    } else if (responseData.message) {
                      // If there's a single error message
                      errorsArray.push(responseData.message);
                    }
                  } else {
                    // Log unexpected errors
                    console.error("Unexpected error:", error);
                    errorsArray.push("An unexpected error occurred.");
                  }

                  // Set errors to be displayed in the UI
                  this.errorsApi = errorsArray;
                })
                .finally(() => {
                  this.loadClicked = false;
                  this.saveClick= false;
                });
        }
      });
    },

    saveWithBill() {
      this.loadClicked = true;
      this.saveClick = true;

      // Validate the form
      this.$validator.validateAll("addEditValidation").then((valid) => {
        if (!valid) {
          this.loadClicked = false;
          this.saveClick = false;
          return this.notifyCustomError("Error", "ValidationErrors");
        }

        let sendData = JSON.parse(JSON.stringify(this.formData));

        let contactZoho = {
          contact_name: this.selectedCustomer.name,
          contact_persons: [
            {
              phone: this.selectedCustomer.phone_number,
            },
          ],
        };

        if (this.zohoError == null) {
          this.$store
            .dispatch("zoho/createContact", contactZoho)
            .then((res) => {
              let contact_id = res.contact.contact_id;
              this.loadClicked = false;

              // Add Zoho contact ID to the customer data
              sendData.customer.contact_id = contact_id;

              if (sendData && sendData.subscribe && sendData.subscribe.paid === null) {
                sendData.subscribe.paid = 0;
              }

              // Format the start date
              if (sendData && sendData.subscribe && sendData.subscribe.start_date) {
                sendData.subscribe.start_date = moment(sendData.subscribe.start_date).format("YYYY-MM-DD");
              }

              // Prevent saving with Zoho again
              sendData.subscribe.save_with_zoho = true;

              // Get the current ID and prepare the endpoint
              sendData.id = this.$route.params.id;
              let routeEndPoint = this.$route.params.type_sub
                ? "newSubscribe/renewalData"
                : "newSubscribe/updateData";

              // Send the subscribe data
              this.$store
                .dispatch(routeEndPoint, sendData)
                .then((res) => {
                  this.notifySuccess("Save", res);
                  this.$router.push({ name: "subscribe" });
                })
                .catch((error) => {
                  let errorsArray = [];

                  // Handle API error response
                  if (error.response && error.response.data) {
                    const responseData = error.response.data;

                    if (Array.isArray(responseData.errors)) {
                      // If errors are an array
                      errorsArray = responseData.errors;
                    } else if (typeof responseData.errors === "object") {
                      // If errors are an object
                      errorsArray = Object.values(responseData.errors);
                    } else if (responseData.message) {
                      // If there's a single error message
                      errorsArray.push(responseData.message);
                    }
                  } else {
                    // Log unexpected errors
                    console.error("Unexpected error:", error);
                    errorsArray.push("An unexpected error occurred.");
                  }

                  // Set errors to be displayed in the UI
                  this.errorsApi = errorsArray;
                })
                .finally(() => {
                  this.saveClick= false;
                  this.loadClicked = false;
                });
            })
            .catch((error) => {
              this.saveClick= false;
              this.loadClicked = false;
              console.error("Zoho contact creation failed:", error);
            });
        }
        else {
          let contact_id = null;
              this.loadClicked = false;

              // Add Zoho contact ID to the customer data
              sendData.customer.contact_id = contact_id;

              if (sendData && sendData.subscribe && sendData.subscribe.paid === null) {
                sendData.subscribe.paid = 0;
              }

              // Format the start date
              if (sendData && sendData.subscribe && sendData.subscribe.start_date) {
                sendData.subscribe.start_date = moment(sendData.subscribe.start_date).format("YYYY-MM-DD");
              }

              // Get the current ID and prepare the endpoint
              sendData.id = this.$route.params.id;
              let routeEndPoint = this.$route.params.type_sub
                ? "newSubscribe/renewalData"
                : "newSubscribe/updateData";

              // Send the subscribe data
              this.$store
                .dispatch(routeEndPoint, sendData)
                .then((res) => {
                  this.notifySuccess("Save", res);
                  this.$router.push({ name: "subscribe" });
                })
                .catch((error) => {
                  let errorsArray = [];

                  // Handle API error response
                  if (error.response && error.response.data) {
                    const responseData = error.response.data;

                    if (Array.isArray(responseData.errors)) {
                      // If errors are an array
                      errorsArray = responseData.errors;
                    } else if (typeof responseData.errors === "object") {
                      // If errors are an object
                      errorsArray = Object.values(responseData.errors);
                    } else if (responseData.message) {
                      // If there's a single error message
                      errorsArray.push(responseData.message);
                    }
                  } else {
                    // Log unexpected errors
                    console.error("Unexpected error:", error);
                    errorsArray.push("An unexpected error occurred.");
                  }

                  // Set errors to be displayed in the UI
                  this.errorsApi = errorsArray;
                })
                .finally(() => {
                  this.loadClicked = false;
                  this.saveClick= false;
                });
        }
      });
    },
  
  },
};
</script>


<style>
.v-col > .v-col {
  padding: 0 !important;
}

#pacakgeFeaturesTable {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#pacakgeFeaturesTable td,
#pacakgeFeaturesTable th {
  border: 1px solid #ccc !important;
  padding: 8px;
}

#pacakgeFeaturesTable th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: right;
  background-color: #04aa6d;
  color: white;
}

.v-switch--paid {
  background-color: #4caf50; /* Green for Paid */
}

.v-switch--not-paid {
  background-color: #f44336; /* Red for Not Paid */
}


.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it appears above everything */
}

.spinner {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #81c784; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

/* Spin Animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Optionally, disable interaction with the rest of the page while the spinner is active */
.page-content {
  pointer-events: none;
}

.fixed-text {
  position: fixed;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #b5f5b9;
  margin-top: 15px;
  z-index: 1001; /* Ensure it's on top of the spinner overlay */
}

</style>